import "./BackBox.css"

export default function BackBox() {
    return (
        <div className={"back-box"}>
            <a href={"/"}>
                <img src={"back.png"}/>
            </a>
        </div>
    )
}