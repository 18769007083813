function ListLoading() {
    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: 30,
            justifyContent: "center",
            alignItems: 'center',
        }}>
            <span>Loading...</span>
        </div>
    )
}

export default ListLoading;