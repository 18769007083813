import './home.css';
import Navbar from "./components/Navbar";
import ContentCard from "../../components/ContentCard";
import ContentList from "../../components/ContentList";
import {useEffect, useState} from "react";
import ListLoading from "../../components/ListLoading";
import LazyAds from "../../components/LazyAds";
import OversizedContentCard from "../../components/OversizedContentCard";
import {addAds} from "../../utils";
import ContentBlock from "../../components/ContentBlock";
import TextHeader from "../../components/TextHeader";
import AdsArea from "../../components/AdsArea";

const categories = [
    {
        title: 'Finance',
    },
    {title: 'News'},
    {title: 'ParentsKids'},
    {title: 'Recipes'},
    {title: 'Dating'},
    {title: 'Fashion'},
    {title: 'Cars'},
    {title: 'Money'},
];

const adsPosition = [5, 9];

function App() {
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [next, setNext] = useState(1);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const category = categories[currentCategoryIndex];

    const loadData = () => {
        setIsLoading(true);
        const url = `https://service.gamescdn.top/feed/${category.title}_${page}.json`;
        if (page === 1) {
            setData([]);
        }
        fetch(url).then(response => {
            return response.json();
        }).then(result => {
            const {next, data: pageData} = result;
            addAds(pageData, adsPosition);
            if (page > 1) {
                setData([...data, ...pageData]);
            } else {
                setData([...pageData])
            }
            setIsLoading(false);
            setNext(next);
        }).catch(e => {
            alert('Load error, please refresh this page');
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadData();
    }, [page, currentCategoryIndex])

    const items = [];
    let adsIndex = 0;
    for (let i = 0; i < data.length; i++) {
        const {title, icon, published_time: publishedTime, ads, type = 1, id} = data[i]
        if (ads) {
            items.push(<LazyAds index={adsIndex} page={category.title} key={`ads_${adsIndex}`}/>)
            adsIndex++;
        } else {
            if (type === 1) {
                items.push(
                    <ContentCard id={id} title={title} icon={icon} publishedTime={publishedTime} key={`${i}`}/>
                )
            } else {
                items.push(
                    <OversizedContentCard id={id} title={title} icon={icon} publishedTime={publishedTime} key={`${i}`}/>
                )
            }
        }
    }

    return (
        <section className={'index-wrap-mobile'}>
            <div className={'header'}>
                <h1 className={'title'} title={'Rolling News'}></h1>
            </div>
            <Navbar
                items={categories}
                onPress={index => {
                    setCurrentCategoryIndex(index);
                    setPage(1)
                    setData([])
                }}
            />
            <ContentBlock>
                <TextHeader title={category.title}/>
                <AdsArea page={category.title} categoryIndex={currentCategoryIndex}/>
                <ContentList
                    style={{paddingBottom: 50}}
                    onBottomReached={() => {
                        if (data.length > 0 && !isLoading && next === 1) {
                            setPage(page + 1);
                        }
                    }}>
                    {items}
                    {data.length > 0 && next === 1 && <ListLoading/>}
                </ContentList>
            </ContentBlock>
            <div className={'footer'}>
                <a href="/privacy-policy"> Privacy Policy </a>
                <a href="/contact-me"> Contact Me </a>
            </div>
        </section>
    );
}

export default App;
