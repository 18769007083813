function addAds(array, positions) {
    positions.sort((a, b) => b - a);
    for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position >= 0 && position <= array.length) {
            array.splice(position, 0, {ads: true});
        }
    }
    return array;
}


export {addAds};