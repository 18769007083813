import "./contact-me.css"
import BackBox from "../../components/BackBox";

export default function ContactMe() {
    return (
        <div className={'cm-text-info'} style={{
            height:"100vh"
        }}>
            <BackBox/>
            <h2>Contact me</h2>
            <div>
                <h3>Singapore</h3>
                <p>350 Orchard Rd, Shaw House, Singapore 238868<br/>
                    <a href="mailto:news-hu@gmail.com" target="">news-hu@gmail.com</a>
                </p>
            </div>
        </div>
    )
}