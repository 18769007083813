import './Navbar.css'
import {useState} from "react";
import NavbarButton from "./NavbarButton";

function Navbar({items, onPress}) {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const buttons = []
    for (let i = 0; i < items.length; i++) {
        const {title} = items[i];
        buttons.push(
            <NavbarButton
                title={title}
                active={selectedIndex === i}
                onPress={() => {
                    setSelectedIndex(i);
                    onPress && onPress(i);
                }}
                key={`${i}`}
            />
        )
    }
    return (
        <ul className={'taps'}>
            {buttons}
        </ul>
    )
}

Navbar.defaultProps = {
    items: [],
}

export default Navbar