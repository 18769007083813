import React from 'react';
import './NavbarButton.css';

function NavbarButton({title, onPress, active}) {
    return (
        <li
            className={`navbar-button ${active ? 'selected' : ''}`}
            onClick={onPress}
        >
            {title}
        </li>
    );
}

export default NavbarButton;
