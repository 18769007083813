import './ContentList.css'

import React, {useEffect, useRef} from 'react';

function ContentList({onBottomReached, children, style}) {
    const bottomObserverRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                onBottomReached();
            }
        });

        if (bottomObserverRef.current) {
            observer.observe(bottomObserverRef.current);
        }

        return () => {
            if (bottomObserverRef.current) {
                observer.unobserve(bottomObserverRef.current);
            }
        };
    }, [onBottomReached]);

    return (
        <div className={'content-list'} style={style}>
            {children}
            <div ref={bottomObserverRef}/>
        </div>
    );
}

export default ContentList;