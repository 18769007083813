import React, {useEffect, useRef, useState} from 'react';

function VisibleLoad({onFirstVisible, children}) {
    const [isVisible, setIsVisible] = useState(false);
    const componentRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting && !isVisible) {
                setIsVisible(true);
                onFirstVisible();
                observer.disconnect();
            }
        });

        if (componentRef.current) {
            observer.observe(componentRef.current);
        }

        return () => {
            if (componentRef.current) {
                observer.unobserve(componentRef.current);
            }
        };
    }, [isVisible, onFirstVisible]);

    return (
        <div ref={componentRef}>
            {/*{isVisible ? children : null}*/}
            {children}
        </div>
    );
}

export default VisibleLoad;
