import './OversizedContentCard.css';
import moment from "moment/moment";

function OversizedContentCard({title, id, icon, publishedTime}) {
    const time = moment(publishedTime).fromNow()
    return (

        <a className="content-card2 oversized" href={`/detail/${id}`}>
            <div className="card-icon2">
                <img src={icon}/>
            </div>
            <div className="card-main2">
                <p className="card-title2">{title}</p>
                <p className="card-info2"><span className="ads-flag">{time}</span></p>
            </div>
        </a>
    )
}

export default OversizedContentCard;