import './ContentCard.css';
import moment from 'moment';

function ContentCard({title, icon, publishedTime, id}) {
    const time = moment(publishedTime).fromNow()
    return (
        <a className={'content-card'} href={`/detail/${id}`}>
            <div className={'card-icon'}>
                <img src={icon}/>
            </div>
            <div className={'card-main'}>
                <p className={'card-title'}>{title}</p>
                <p className={'card-info'}>{time}</p>
            </div>
        </a>
    )
}

export default ContentCard;